<template>
  <main class="detail">
    <router-link :to="{ name: 'Expense' }" class="link">
      <svg
        class="back-icon"
        color="hsl(252, 94%, 67%)"
        viewBox="0 0 1024 1024"
        style="stroke: currentcolor; fill: currentcolor"
      >
        <path
          d="M730.6 18.4l-505.4 505.2 505.4 505.4 144.8-144.8-360.6-360.6 360.6-360.4z"
        ></path>
      </svg>
      <span class="back-text">Back</span>
    </router-link>
    <pulse-loader class="loader" color="#fff" v-if="isLoading" />
    <template v-else>
      <div class="status-container">
        <p class="status-title">Status</p>
        <p
          class="status-body"
          :class="[
            invoice.expense_status === 'pending'
              ? 'pending'
              : 'paid',
          ]"
        >
          <span class="status-circle">.</span> {{ invoice.expense_status }}
        </p>
        <div class="columns btn-container">
          <button
            class="btn btn-edit"
            @click="editInvoice"
          >
            Edit
          </button>
          <button class="btn btn-delete" @click="deleteItem">Hapus</button>
          <button
            class="btn btn-mark"
            v-if="invoice.expense_status === 'pending'"
            @click="markAsPaid"
          >
            <clip-loader v-if="paidLoading" color="white" size="12px" />
            <template v-else> Tandai Sudah Dibayar </template>
          </button>
          <button class="btn btn-print" @click="doPrint">Print</button>
        </div>
      </div>
      <div class="details">
        <div class="project-info">
          <p class="project-id">#{{ invoice.id }}</p>
          <p class="project-desc">{{ invoice.invoice_description }}</p>
        </div>
        <div class="adress">
          <p class="adress-street">{{ invoice.address.client_address }}</p>
          <p class="adress-city">{{ invoice.address.client_city }}</p>
          <p class="adress-postcode">{{ invoice.address.client_postCode }}</p>
          <p class="adress-country">{{ invoice.address.client_country }}</p>
        </div>
        <div class="invoice_detail">
          <div class="columns">
            <div class="column is-4">
              <p class="label">Tanggal Invoice</p>
              <p class="body">{{ invoice.invoice_date }}</p>
            </div>
            <div class="column is-4">
              <p class="label">Tagihan Kepada:</p>
              <p class="body">{{ invoice.client_name }}</p>
            </div>
            <div class="column is-4">
              <p class="label">No. HP:</p>
              <p class="body">{{ invoice.client_email }}</p>
            </div>
          </div>
          <div class="columns">
            <div class="column is-4">
              <p class="label">Vendor</p>
              <p class="body">{{ invoice.vendor }}</p>
            </div>
            <div class="column is-4">
              <p class="label">Pembayaran</p>
              <p class="body">{{ invoice.payment.bank }} {{ invoice.payment.no_rek }}</p>
            </div>
            <div class="column is-4">
              <p class="label">Kurir:</p>
              <p class="body">{{ invoice.kurir.nama }} {{ invoice.kurir.resi }}</p>
            </div>
          </div>
          <div class="columns">
            <div class="column is-4">
              <p class="label">Pengiriman:</p>
              <p class="body">{{ invoice.kurir.penerima }}</p>
              <p class="body">{{ invoice.kurir.alamat_kirim }}</p>
            </div>
            <div class="column is-4">
              <p class="label">Ucapan:</p>
              <p class="body">{{ invoice.kurir.ucapan }}</p>
            </div>
            <div class="column is-4">
              <p class="label">Referral:</p>
              <p class="body">{{ invoice.payment.referral }}</p>
            </div>
          </div>
        </div>
        <div class="item-container">
          <p>Nama Item</p>
          <p>Total</p>
          <div
            class="project-item"
            v-for="(item, index) in invoice.costs"
            :key="index"
          >
            <p class="prj-text">{{ item.name }}</p>
            <p class="prj-text">
              {{ currencyFormat(item.price) }}
            </p>
          </div>
        </div>

        <div class="amount">
          <div class="amount-text">
            <p class="">Total</p>
            <p class="amount-number">
              {{ currencyFormat(invoice.total_cost) }}
            </p>
          </div>
          <!-- <div class="amount-text">
            <p class="">Jumlah Total</p>
            <p class="amount-number">
              {{ currencyFormat(invoice.total) }}
            </p>
          </div> -->
        </div>
      </div>
    </template>
    <FormComp />
  </main>
</template>

<script>
import Axios from "axios";
import { mapMutations, mapState } from "vuex";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import FormComp from "./Form.vue";

export default {
  name: "InvoiceDetail",
  components: {
    PulseLoader,
    ClipLoader,
    FormComp,
  },
  props: {
    id: {
      default: null,
    },
    index: Number,
  },
  data() {
    return {
      invoice: {},
      isLoading: true,
      paidLoading: false,
    };
  },
  computed: {
    ...mapState(["refresh"]),
  },
  methods: {
    getData() {
      this.isLoading = true;
      Axios.get(`/invoice/${this.id}`)
        .then((res) => {
          this.invoice = res.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    ...mapMutations([
      "DELETE_INVOICE",
      "MARK_INVOICE",
      "SET_MENU_IS_OPEN",
      "SET_EDIT",
      "REFRESH_LIST"
    ]),

    deleteItem() {
      this.$buefy.dialog.confirm({
          message: 'Apakah anda yakin untuk menghapus invoice ini?',
          confirmText: 'Delete Invoice',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => {
            Axios.delete(`/invoice/${this.id}/delete`)
              .then((res) => {
                this.$router.push({ name: "Home" });
                console.log(res);
              })
              .catch((err) => {
                console.log(err);
              });
          }
      })

    },
    markAsPaid() {
      this.paidLoading = true;
      Axios.put(`/invoice/${this.id}/expense/paid`)
        .then((res) => {
          console.log(res);
          this.invoice = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.paidLoading = false;
        });
    },
    editInvoice() {
      this.SET_EDIT({ status: true, id: this.invoice.id });
      this.SET_MENU_IS_OPEN();
    },
    currencyFormat(v) {
      return new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
      }).format(v)
    },
    doPrint() {
      window.print();
    }
  },
  created() {
    this.getData();
  },
  watch: {
    refresh(val) {
      if (val) {
        this.getData();
        this.REFRESH_LIST();
      }
    },
  },
};
</script>

<style lang="scss">
  @import "~bulma/sass/utilities/_all";
  @import "~bulma/sass/grid/_all";
</style>
<style scoped>
.loader {
  text-align: center;
  top: 50%;
  position: absolute;
  left: 50%;
}
.detail {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 50px 150px 50px 220px;
  color: white;
}
.link {
  text-decoration: none;
}
.back-icon {
  width: 10px;
  height: 10px;
}
.back-text {
  color: white;
  font-weight: 700;
  margin-left: 20px;
}
.status-container {
  padding: 20px 24px;
  display: flex;
  align-items: center;
  background-color: #1e2238;
  border-radius: 8px;
}
.status-title {
  font-weight: 600;
  color: hsl(231, 75%, 93%);
  flex-basis: 60px;
}
.status-body {
  min-width: 105px;
  padding: 13px 0 13px 40px;
  border-radius: 6px;
  font-weight: 700;
  display: flex;
  align-items: center;
  position: relative;
}
.status-circle {
  font-size: 40px;
  position: absolute;
  left: 15px;
  top: -14px;
}
.draft {
  background-color: #292c45;
  color: rgb(224, 228, 251);
}
.pending {
  background-color: rgba(255, 145, 0, 0.06);
  color: rgb(255, 145, 0);
}
.paid {
  background-color: rgba(51, 215, 160, 0.06);
  color: rgb(51, 215, 160);
}
.btn-container {
  margin-left: auto;
}
.btn {
  padding: 10px 24px;
  border: none;
  border-radius: 24px;
  color: white;
  font-weight: 700;
  cursor: pointer;
}
.btn-edit {
  background-color: #252946;
  margin-left: auto;
}
.btn-edit:hover {
  background-color: #1b1d32;
}
.btn-delete {
  background-color: #ec5555;
  margin-left: 10px;
}
.btn-delete:hover {
  background-color: #fb999a;
}
.btn-mark {
  background-color: #7b5cfa;
  margin-left: 10px;
}
.btn-mark:hover {
  background-color: #9175ff;
}
.btn-print {
  background-color: #3f51b5;
  margin-left: 10px;
}
.btn-print:hover {
  background-color: #03a9f4;
}
.details {
  padding: 20px 30px;
  display: grid;
  background-color: #1e2238;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  border-radius: 8px;
}
.project-info {
  grid-column-start: 1;
  grid-column-end: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}
.project-id {
  font-size: 16px;
  font-weight: 700;
}
.label {
  margin-bottom: 10px;
}
.body {
  font-size: 15px;
  font-weight: 700;
}
.adress {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  grid-column-start: 3;
  grid-column-end: 4;
}
.invoice_detail {
  grid-column-start: 1;
  grid-column-end: 4;
}
.item-container {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 4;
  grid-row-end: 5;
  padding: 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #252946;
  display: grid;
  grid-template-columns: 2fr repeat(1, 1fr);
  row-gap: 20px;
}
.project-item {
  grid-column-start: 1;
  grid-column-end: 5;
  display: grid;
  grid-template-columns: 2fr repeat(1, 1fr);
}
.prj-text {
  font-weight: 700;
}
.amount{
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 5;
  grid-row-end: 6;
  padding: 20px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: #0d0e17;
  margin-top: -20px;
}
.amount-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
}
.amount-number {
  font-size: 1rem;
  font-weight: 700;
}

@media screen and (max-width: 1024px) {
  .detail {
    padding: 100px 120px 20px 120px;
  }
  .status-container {
    display: grid;
    gap: 20px;
  }
  .status-title {
    grid-column-start: 1;
    grid-column-end: 2;
  }
  .status-body {
    grid-column-start: 4;
    grid-column-end: 5;
    width: 100%;
  }
  .btn-container {
    grid-column-start: 1;
    grid-column-end: 5;
    margin: auto;
  }
}

@media screen and (max-width: 750px) {
  .detail {
    padding: 100px 20px 20px 20px;
  }
  .details {
    grid-template-columns: repeat(1, 0.5fr);
  }
  .btn {
    padding: 5px 10px;
  }
  .is-hidden-mobile {
    display: none;
  }
  .item-container, .project-item {
    grid-template-columns: 2fr repeat(1, 1fr);
  }
}
</style>
